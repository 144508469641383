import { Box } from '@mui/material';
import Chip from '../../../../../components/shared/chip';
import TaskRemark from '../TaskRemark';
import { getFirstCharOfEveryWord } from '../../../../../utils/project';
import { capitalize } from '../../../../../utils/string';

export const columns = (
  handleViewTaskDetails = () => null,
  updateRemarkCall = () => null,
  projectStatus = '',
) => [
  {
    id: 'id',
    headerName: 'Task ID',
    field: 'externalId',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
  },
  {
    id: 'name',
    headerName: 'Task Name',
    field: 'name',
    minWidth: 700,
    width: 700,
    maxWidth: 700,
    onCellClicked: handleViewTaskDetails,
  },
  {
    id: 'remark',
    headerName: 'Remark',
    field: 'remark',
    minWidth: 280,
    width: 280,
    maxWidth: 280,
    cellRenderer: data => {
      return <TaskRemark data={data} updateRemarkCall={updateRemarkCall} status={projectStatus} />;
    },
  },
  {
    id: 'date',
    headerName: 'Start Date - End Date',
    minWidth: 280,
    width: 280,
    maxWidth: 280,
    cellRenderer: data =>
      data.startDate &&
      data.endDate && (
        <Box>
          {data.startDate} - {data.endDate}
        </Box>
      ),
  },
  {
    id: 'status',
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    maxWidth: 200,
    cellRenderer: data => {
      let statusElement = null;
      const tempStatus = data.status ? capitalize(data.status) : '';

      switch (data.status) {
        case 'NEW':
          statusElement = <Chip status="warning" label={tempStatus} styles={{ margin: 'auto' }} />;
          break;
        case 'TODO':
          statusElement = <Chip status="warning" label={tempStatus} styles={{ margin: 'auto' }} />;
          break;
        case 'IN_PROGRESS':
          statusElement = <Chip status="warning" label={tempStatus} styles={{ margin: 'auto' }} />;
          break;
        case 'COMPLETED':
          statusElement = <Chip status="success" label={tempStatus} styles={{ margin: 'auto' }} />;
          break;
        case 'CANCELLED':
          statusElement = <Chip status="error" label={tempStatus} styles={{ margin: 'auto' }} />;
          break;
        default:
          statusElement = <Chip status="warning" label={tempStatus} styles={{ margin: 'auto' }} />;
      }
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {statusElement}
          </Box>
        </Box>
      );
    },
  },
  {
    id: 'requestStatus',
    headerName: 'Approval Status',
    field: 'requestStatus',
    minWidth: 150,
    maxWidth: 200,
    cellRenderer: data => {
      let statusElement = null;
      const tempStatus = data.requestStatus ? capitalize(data.requestStatus) : '';

      switch (data.requestStatus) {
        case 'APPROVED':
          statusElement = <Chip status="success" label={tempStatus} styles={{ margin: 'auto' }} />;
          break;
        default:
          statusElement = <Chip status="warning" label={tempStatus} styles={{ margin: 'auto' }} />;
      }
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {tempStatus ? statusElement : '-'}
          </Box>
        </Box>
      );
    },
  },
  {
    id: 'status',
    field: 'status',
    headerName: 'Assigned Teams',
    minWidth: 200,
    maxWidth: 300,
    cellRenderer: data => {
      let statusElement = null;
      return (
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }}
        >
          {data?.assignedTeam?.length > 0
            ? data?.assignedTeam.map(i => {
                return (
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      background: 'var(--primary-light)',
                      color: 'var(--white)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '6px',
                    }}
                  >
                    {getFirstCharOfEveryWord(i)}
                  </Box>
                );
              })
            : '-'}
        </Box>
      );
    },
  },
];

export const childColumns = (handleViewSubTaskTaskDetails = () => null) => [
  {
    id: 'id',
    headerName: 'Task ID',
    field: 'id',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
  },
  {
    id: 'name',
    headerName: 'Task Name',
    field: 'name',
    minWidth: 900,
    width: 900,
    maxWidth: 900,
    onCellClicked: handleViewSubTaskTaskDetails,
  },
  {
    id: 'date',
    headerName: 'Start Date - End Date',
    minWidth: 280,
    width: 280,
    maxWidth: 280,
    cellRenderer: data =>
      data.startDate &&
      data.endDate && (
        <Box>
          {data.startDate} - {data.endDate}
        </Box>
      ),
  },
  {
    id: 'status',
    field: 'status',
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: data => {
      let statusElement = null;
      const tempStatus = data.status ? capitalize(data.status) : '';

      switch (data.status) {
        case 'NEW':
          statusElement = <Chip status="warning" label={tempStatus} styles={{ margin: 'auto' }} />;
          break;
        case 'TODO':
          statusElement = <Chip status="warning" label={tempStatus} styles={{ margin: 'auto' }} />;
          break;
        case 'IN_PROGRESS':
          statusElement = <Chip status="warning" label={tempStatus} styles={{ margin: 'auto' }} />;
          break;
        case 'COMPLETED':
          statusElement = <Chip status="success" label={tempStatus} styles={{ margin: 'auto' }} />;
          break;
        case 'CANCELLED':
          statusElement = <Chip status="error" label={tempStatus} styles={{ margin: 'auto' }} />;
          break;
        default:
          statusElement = <Chip status="warning" label={tempStatus} styles={{ margin: 'auto' }} />;
      }
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Box
            sx={{
              width: '40px',
              height: '40px',
              background: 'var(--primary-light)',
              color: 'var(--white)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '6px',
            }}
          >
            BT
          </Box>
          <Box
            sx={{
              width: 'calc(100% - 40px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {statusElement}
          </Box>
        </Box>
      );
    },
  },
];
