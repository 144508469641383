import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../../../../components/shared/card';
import Tabs from '../../../../../../components/shared/tabs';
import Button from '../../../../../../components/shared/buttons';
import Header from '../../../../../../components/shared/header';
import { DeleteIcon, DownloadIcon } from '../../../../../../libs/svg-icons/icons';
import Table from '../../../../../../components/shared/table';
import { childColumns, columns as columnData } from './ColumnData';
import Chip from '../../../../../../components/shared/chip';
import Dialog from '../../../../../../components/shared/dialog';
import ClientPoDialog from '../../../../../../components/shared/dialog/ClientPODialog';
import Comments from '../../../../../../components/shared/comments';
import Pagination from '../../../../../../components/shared/table/pagination';
import {
  cancelRestoreBuyingBrief,
  createBBOnMinder,
  getBuyingBrief,
  updateBBOnMinder,
} from '../../../../../../services/media-plan-service';
import { formatDate } from '../../../../../../libs/date/format';
import Loader from '../../../../../../components/shared/loader';
import { AppConstants } from '../../../../../../constants/app-constants';
import { useEnqueueSnackbar } from '../../../../../../components/shared/toast-provider/toastHook';
import { formatCurrency } from '../../../../../../utils/string';
import { hasAllPermissions } from '../../../../../../libs/permissions';
import { PermissionsConstants } from '../../../../../../constants/permissions-constants';
import ErrorInHeader from '../../../../../../components/shared/errorInHeader';
import OverflowTooltip from '../../../../../../components/shared/tooltip/OverflowTooltip';
import BackdropOverlay from '../../../../../../components/shared/backdropOverlay';
import { HttpStatusCode } from 'axios';

const MinderBBStatusMap = {
  NOT_CREATED_ON_MINDER: 'Not Created on Minder',
  CREATED_AND_MATCHED: 'Created & Matched',
  CREATED_AND_NOT_MATCHED: 'Created but not Matched',
};

const BuyingBrief = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();

  const enqueueSnackbar = useEnqueueSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [openViewPoClientDialog, setOpenViewPoClientDialog] = useState(false);
  const [buyingBriefs, setBuyingBriefs] = useState([]);
  const [pagination, setPagination] = useState({
    pageNo: 0,
    pageSize: 2,
    isFirst: true,
    isLast: true,
    totalElements: 1,
    totalPages: 1,
  });

  const [selectedRow, setSelectedRow] = useState(-1);
  const [headerError, setHeaderError] = useState(null);

  const [backdropOpen, setBackDropOpen] = useState(false);

  const handleOpenViewPoClientDialog = () => {
    setOpenViewPoClientDialog(true);
  };
  const handleCloseViewPoClientDialog = () => {
    setOpenViewPoClientDialog(false);
  };
  const handleChangeRowsPerPage = value => {
    setPagination(prev => ({
      ...prev,
      pageSize: value,
    }));
  };
  const handleChangePage = value => {
    setPagination(prev => ({
      ...prev,
      pageNo: value,
    }));
  };

  const fetchData = useCallback(
    (pageNo, pageSize, bbgId) => {
      const param = {
        pageNo,
        pageSize,
        bbgId,
      };
      setIsLoading(true);
      getBuyingBrief(param)
        .then(response => {
          const {
            isFirst = true,
            isLast = true,
            totalElements = 1,
            totalPages = 1,
            models = [],
          } = response?.data?.data ?? {};

          setPagination(prev => ({
            ...prev,
            isFirst,
            isLast,
            totalElements,
            totalPages,
          }));
          setBuyingBriefs(models);
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          if (error?.response?.status === HttpStatusCode.Forbidden) {
            setBackDropOpen(true);
          } else {
            enqueueSnackbar(message, 'error');
          }
        })
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    fetchData(pagination.pageNo + 1, pagination.pageSize, params.bgid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageNo, pagination.pageSize, params.bgid]);

  const handleActivateBBOnWebApp = useCallback(
    rowId => {
      const payload = {
        bbId: rowId,
        status: 'RESTORE',
      };
      setIsLoading(true);
      cancelRestoreBuyingBrief(payload)
        .then(response => {
          fetchData(pagination.pageNo + 1, pagination.pageSize, params.bgid);
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar, fetchData, pagination.pageNo, pagination.pageSize, params.bgid],
  );

  const handleRowCancel = rowId => {
    setSelectedRow(rowId);
  };

  const onMenuClick = useCallback(
    (option, rowId) => {
      switch (option) {
        case 'Activate':
          handleActivateBBOnWebApp(rowId);
          break;
        case 'Cancel Row':
          handleRowCancel(rowId);
          break;
        default:
          break;
      }
    },
    [handleActivateBBOnWebApp],
  );

  const handleCancelConfirmationClose = () => {
    setSelectedRow(-1);
  };

  const handelCancelRowOnBBConfirmationClicked = useCallback(() => {
    const payload = {
      bbId: selectedRow,
      status: 'CANCELLED',
    };
    setIsLoading(true);
    cancelRestoreBuyingBrief(payload)
      .then(response => {
        fetchData(pagination.pageNo + 1, pagination.pageSize, params.bgid);
        setSelectedRow(-1);
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    enqueueSnackbar,
    fetchData,
    pagination.pageNo,
    pagination.pageSize,
    params.bgid,
    selectedRow,
  ]);

  const cancelRowDialog = useMemo(
    () => (
      <Dialog
        open={selectedRow > -1}
        onClose={handleCancelConfirmationClose}
        icon={
          <Box
            sx={{
              width: '52px',
              height: '52px',
              borderRadius: '50%',
              background: theme.palette.background,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              marginBottom: '24px',
            }}
          >
            <DeleteIcon fill="var(--deep-navy)" width="32px" height="32px" />
          </Box>
        }
        headline={<Typography variant="SemiBold-22">Cancel Row</Typography>}
        description={
          <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
            Are you sure you want to cancel this row?
          </Typography>
        }
        buttons={[
          <Button
            key="button1"
            width="176px"
            variant="outlined"
            label="No"
            onClick={handleCancelConfirmationClose}
          />,
          <Button
            key="button2"
            width="176px"
            variant="contained"
            label="Yes"
            onClick={handelCancelRowOnBBConfirmationClicked}
          />,
        ]}
        width="420px"
        headlineAlignment="center"
      />
    ),
    [selectedRow],
  );

  const handleUpdateOnMinder = bbId => {
    if (hasAllPermissions([PermissionsConstants.CREATE_MINDER_IB])) {
      setIsLoading(true);
      const payload = {
        'buyingBriefId': bbId,
      };
      updateBBOnMinder(payload)
        .then(response => {
          const { message = '' } = response?.data?.data ?? {};
          enqueueSnackbar(message, 'success');
        })
        .then(() => fetchData(pagination.pageNo || 1, pagination.pageSize, params.bgid))
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
          setHeaderError(message);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleCreateOnMinder = bbId => {
    if (hasAllPermissions([PermissionsConstants.CREATE_MINDER_IB])) {
      setIsLoading(true);
      const payload = {
        'buyingBriefId': bbId,
      };
      createBBOnMinder(payload)
        .then(response => {
          const { data } = response?.data ?? {};
          enqueueSnackbar(data?.message, 'success');
        })
        .then(() => fetchData(pagination.pageNo || 1, pagination.pageSize, params.bgid))
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
          setHeaderError(message);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const fetchBBdata = () => {
    fetchData(pagination.pageNo || 1, pagination.pageSize, params.bgid);
  };

  const navigateProject = projId => {
    navigate(`/app/projects/${projId}/view-project`);
  };

  const columns = useMemo(() => columnData(onMenuClick), [onMenuClick]);
  const childColumnsData = useMemo(
    () => childColumns(fetchBBdata, navigateProject),
    [fetchBBdata, navigateProject],
  );

  const getMaterialStr = data => {
    if (data) {
      let dataArr = Object.keys(data);

      if (dataArr && dataArr.length) {
        let strArr = dataArr.map(ele => {
          let str = '';
          Object.keys(data[ele]).map(i => {
            if (i !== 'seperateBudget' && i !== 'endDate' && i !== 'startDate') {
              str += `${data[ele][i]} `;
            } else if (i === 'seperateBudget') {
              let xstr = data[ele].seperateBudget;
              if (xstr.length) {
                let x = xstr[0];
                str += `${x.budget} ${x.grp} ${x.rf}`;
              }
            }
          });
          return str;
        });
        return strArr.join(', ');
      }
    }

    return '';
  };

  const BuyingBriefTablesWithPagination = useMemo(() => {
    if (buyingBriefs.length === 0 && !isLoading) {
      return (
        <div
          style={{ width: '100%', padding: '100px', textAlign: 'center', fontFamily: 'Poppins' }}
        >
          Buying Brief Not Found
        </div>
      );
    }
    return buyingBriefs.map(buyingBrief => {
      const data = [
        buyingBrief?.minderBbNumber
          ? {
              id: null,
              minderBbNumber: buyingBrief?.minderBbNumber || '-',
              bbNumber: null,
              name: 'Minder',
              buyingBriefStatus: buyingBrief?.minderStatus,
              agency: buyingBrief?.minderAgency || '-',
              client: buyingBrief?.minderClient || '-',
              businessUnit: buyingBrief?.minderBusinessUnit || '-',
              category: buyingBrief?.minderCategory?.name || '-',
              brand: buyingBrief?.minderBrand?.name || '-',
              variantFinanceName: buyingBrief?.minderVarientFinance?.name || '-',
              mediaType: buyingBrief?.minderMediaType || '-',
              campaignName: buyingBrief?.minderCampaignName || '-',
              startDate: buyingBrief?.minderStartDate
                ? formatDate(new Date(buyingBrief?.minderStartDate), 'dd/MM/yyyy')
                : '-',
              endDate: buyingBrief?.minderEndDate
                ? formatDate(new Date(buyingBrief?.minderEndDate), 'dd/MM/yyyy')
                : '-',
              targetAudience: buyingBrief?.minderTargetAudience || '-',
              marvelBBId: buyingBrief?.minderMarvelId || '-',
              material: buyingBrief?.minderMaterial,
              totalBudget: buyingBrief?.minderTotalBudget
                ? formatCurrency(buyingBrief?.minderTotalBudget)
                : '-',
              actualCost: buyingBrief?.minderActualCost
                ? formatCurrency(buyingBrief?.minderActualCost)
                : '-',
              remarks: buyingBrief?.minderRemark || '-',
              attachment: buyingBrief?.minderAttachment || '-',
              currency: 'IDR',
              cancelStatus: buyingBrief?.minderStatus === 'CANCELLED' ? 'Yes' : 'No',
            }
          : {
              id: '-',
              bbNumber: '-',
              name: 'Minder',
              buyingBriefStatus: '-',
              agency: '-',
              client: '-',
              businessUnit: '-',
              category: '-',
              brand: '-',
              variantFinanceName: '-',
              mediaType: '-',
              campaignName: '-',
              startDate: '-',
              endDate: '-',
              targetAudience: '-',
              marvelBBId: '-',
              material: {},
              totalBudget: '-',
              actualCost: '-',
              remarks: '-',
              attachment: '-',
              currency: 'IDR',
              cancelStatus: '-',
            },
        buyingBrief?.webappMarvelId
          ? {
              id: buyingBrief?.id || '-',
              bbNumber: null,
              name: 'Web App',
              buyingBriefStatus: buyingBrief?.webappStatus || '-',
              agency: buyingBrief?.webappAgency || '-',
              client: buyingBrief?.webappClient || '-',
              businessUnit: buyingBrief?.webappBusinessUnit || '-',
              category: buyingBrief?.webappCategory?.name || '-',
              brand: buyingBrief?.webappBrand?.name || '-',
              variantFinanceName: buyingBrief?.webappVarientFinance?.name || '-',
              mediaType: buyingBrief?.webappMediaType || '-',
              campaignName: buyingBrief?.webappCampaignName || '-',
              startDate: buyingBrief?.webappStartDate
                ? formatDate(new Date(buyingBrief?.webappStartDate), 'dd/MM/yyyy')
                : '-',
              endDate: buyingBrief?.webappEndDate
                ? formatDate(new Date(buyingBrief?.webappEndDate), 'dd/MM/yyyy')
                : '-',
              targetAudience: buyingBrief?.webappTargetAudience || '-',
              marvelBBId: buyingBrief?.webappMarvelId || '-',
              material: buyingBrief?.webappMaterial || '-',
              totalBudget: buyingBrief?.webappTotalBudget
                ? formatCurrency(buyingBrief?.webappTotalBudget)
                : '-',
              actualCost: buyingBrief?.webbappActualCost
                ? formatCurrency(buyingBrief?.webbappActualCost)
                : '-',
              remarks: buyingBrief?.webappRemark || '-',
              attachment: buyingBrief?.webappAttachment || '-',
              currency: 'IDR',
              cancelStatus: buyingBrief?.webappStatus === 'CANCELLED' ? 'Yes' : 'No',
            }
          : {},
      ];

      const childData = buyingBrief?.webappMediaPlanRows?.map(row => ({
        ...row,
        taDigital: row?.taDigital ? row?.taDigital : 'NA',
        parentId: buyingBrief?.id,
        startDate: formatDate(row?.startDate, 'dd/MM/yyyy'),
        startDateYear: row?.startDate,
        endDate: formatDate(row?.endDate, 'dd/MM/yyyy'),
        brand: row?.brand?.name,
        category: row?.category?.name,
        campaign: row?.campaign?.name,
        variantFinanceCode: row?.variantFinanceName?.externalId,
        variantFinanceName: row?.variantFinanceName?.name,
        materialName: row?.materialName?.name,
        materialCode: row?.materialCode,
        format: row?.format?.name,
        type: row?.type?.name,
        brandFinance: row?.brandFinance?.name,
        mediaType: row?.mediaType?.name,
        expenditureType: row?.expenditureType?.externalId,
        activityInput: row?.activityInput?.name
          ? `${row?.activityInput?.name} ${row?.campaign?.name}`
          : null,
        placement: row?.placement?.name,
        partner: row?.partner?.name,
        minderTA: row?.minderTA?.name,
        clusterTA: row?.clusterTA?.name,
        month: row?.month?.name,
        quarter: row?.quarter?.name,
        half: row?.half?.name,
        frequency: row?.frequency?.name,
        duration: row?.duration?.name,
        planBudget: row?.planBudget ? formatCurrency(row?.planBudget) : null,
        ioNumber: row?.ioNumber ? row?.ioNumber : null,
        ngrp: row?.ngrp ? row?.ngrp : '-',
        ngrpReport: row?.ngrpReport ? row?.ngrpReport : '-',
        acd: row?.acd ? row?.acd : '-',
        grp: row?.grp ? row?.grp : '-',
        bbNumberMinder: buyingBrief?.minderBbNumber || '-',
        businessUnit: buyingBrief?.webappBusinessUnit || '-',
        minderBbNumber: buyingBrief?.minderBbNumber || '-',
        bbId: buyingBrief?.id || '-',
        tvReach: row?.tvReach || '-',
        jtbd: row?.jtbd || '-',
        discrepancyStatus: buyingBrief?.discrepancyStatus,
        webAppBuyingBriefStatus: buyingBrief?.webappStatus || '-',
        minderBuyingBriefStatus: buyingBrief?.minderStatus || '-',
        projectId: row?.projectId || '',
        isBbUpdatedOnMinder: buyingBrief?.isBbUpdatedOnMinder,
        isMediaPlanRowUpdated: row?.isMediaPlanRowUpdated,
      }));

      return (
        <Box
          key={buyingBrief.id}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '20px',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '16px',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '40%',
                }}
              >
                {buyingBrief?.discrepancyStatus === AppConstants.CREATED_AND_MATCHED ||
                buyingBrief?.discrepancyStatus === AppConstants.CREATED_AND_NOT_MATCHED ? (
                  <Button
                    label="Update on Minder"
                    onClick={() => handleUpdateOnMinder(buyingBrief?.id)}
                    disabled={
                      buyingBrief?.discrepancyStatus === AppConstants.CREATED_AND_MATCHED ||
                      buyingBrief?.minderStatus === 'CANCELLED'
                    }
                    variant="contained"
                  />
                ) : (
                  <Button
                    label="Create on Minder"
                    onClick={() => handleCreateOnMinder(buyingBrief?.id)}
                    disabled={buyingBrief?.webappStatus === 'CANCELLED'}
                    variant="contained"
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '60%',
                  justifyContent: 'flex-end',
                  gap: '20px',
                }}
              >
                <Box
                  sx={{
                    width: '429px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '20px',
                    backgroundColor: theme.palette.background,
                    borderRadius: '6px',
                    border: `1px solid ${theme.palette.text['500']}`,
                    padding: '12px',
                  }}
                >
                  <Box sx={{ width: 'calc(50% - 12px)', minWidth: '170px' }}>
                    <Typography variant="Medium-14" color={theme.palette.text['300']}>
                      BB Number from Minder
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: 'calc(50% - 12px)',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                    }}
                  >
                    <OverflowTooltip text={buyingBrief?.minderBbNumber}>
                      {buyingBrief?.minderBbNumber}
                    </OverflowTooltip>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '429px',
                    display: 'flex',
                    height: '51px',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '20px',
                    backgroundColor: theme.palette.background,
                    borderRadius: '6px',
                    border: `1px solid ${theme.palette.text['500']}`,
                    padding: '9px 12px',
                  }}
                >
                  <Box sx={{ width: 'calc(50% - 12px)' }}>
                    <Typography variant="Medium-14" color={theme.palette.text['300']}>
                      Discrepancy Status
                    </Typography>
                  </Box>
                  {buyingBrief?.discrepancyStatus && (
                    <Box
                      sx={{
                        width: 'calc(50% - 10px)',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Chip
                        sx={{ height: '30px' }}
                        status={
                          buyingBrief?.discrepancyStatus === AppConstants.CREATED_AND_MATCHED
                            ? 'success'
                            : 'warning'
                        }
                        label={
                          <OverflowTooltip text={MinderBBStatusMap[buyingBrief?.discrepancyStatus]}>
                            {MinderBBStatusMap[buyingBrief?.discrepancyStatus]}
                          </OverflowTooltip>
                        }
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Table
            variant="mui-table"
            datasource={null}
            data={data}
            columns={columns}
            childData={childData}
            childColumns={childColumnsData}
            childStyles={{
              padding: '8px',
            }}
            hasChild
          />
        </Box>
      );
    });
  }, [buyingBriefs, columns, theme.palette.background, theme.palette.text]);

  return (
    <>
      <Loader isLoading={isLoading} />

      {backdropOpen && (
        <BackdropOverlay
          open={backdropOpen}
          buttonLabel="Redirect To Media Plan List"
          clickAction={() => {
            navigate(`/app/media-plans`);
          }}
        />
      )}

      <Card sx={{ alignItems: 'flex-start' }}>
        {headerError && (
          <ErrorInHeader
            onClose={() => {
              setHeaderError(null);
            }}
            content={<Typography variant="Medium-14">{headerError}</Typography>}
          />
        )}
        <Header
          breadcrumbs={[
            { name: 'View Media Plan', href: `/app/media-plans/${params.id}/media-plan` },
            { name: 'Buying Group', href: `/app/media-plans/${params.id}/buying-group` },
            { name: params?.bgid },
          ]}
          backHref={`/app/media-plans/${params.id}/buying-group`}
          rightActions={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1.25rem',
              }}
            >
              <ClientPoDialog
                open={openViewPoClientDialog}
                onClose={handleCloseViewPoClientDialog}
                headline="View Client PO"
                width="1252px"
                img={''}
                buttons={
                  <Button
                    width="40px"
                    height="40px"
                    sx={{
                      minWidth: '21px',
                      padding: '5px',
                    }}
                    variant="outlined"
                    startIcon={
                      <Box
                        component={'span'}
                        sx={{
                          width: '21px',
                          height: '21px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <DownloadIcon fill={theme.palette.primary.main} />
                      </Box>
                    }
                  />
                }
              />
              {buyingBriefs?.length > 0 && (
                <Chip
                  status="success"
                  label="PO Client Received"
                  styles={{
                    height: '40px',
                  }}
                  // onClick={handleOpenViewPoClientDialog}
                />
              )}
            </Box>
          }
        />
        <Tabs
          items={[
            {
              value: 'buying-brief-group',
              label: 'Buying Brief Group',
              link: `/app/media-plans/${params.id}/buying-group/${params.bgid}/`,
            },
            {
              value: 'buying-brief',
              label: 'Buying Brief',
              link: `/app/media-plans/${params.id}/buying-group/${params.bgid}/buying-brief`,
            },
          ]}
          active="buying-brief"
        />

        {BuyingBriefTablesWithPagination}
        {buyingBriefs.length !== 0 && (
          <Box style={{ width: '100%', marginTop: '-15px' }}>
            <Pagination
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNo}
              setRowsPerPage={handleChangeRowsPerPage}
              setPage={handleChangePage}
              totalItems={pagination.totalElements}
            />
          </Box>
        )}
        {cancelRowDialog}

        <Comments entityId={params?.bgid} entityType="BUYING_BRIEF" />
      </Card>
    </>
  );
};

export default BuyingBrief;
