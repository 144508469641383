import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Header from '../../../../../components/shared/header';
import Card from '../../../../../components/shared/card';
import { Box, Divider, Typography } from '@mui/material';
import Chip from '../../../../../components/shared/chip';
import OverflowTooltip from '../../../../../components/shared/tooltip/OverflowTooltip';
import { useTheme } from '@mui/material/styles';
import Dialog from '../../../../../components/shared/dialog';
import Table from '../../../../../components/shared/table';
import Accordion from '../../../../../components/shared/accordion';
import Select from '../../../../../components/shared/form/Select/select';
import Button from '../../../../../components/shared/buttons';
import { columns } from './ColumnData';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../../../../components/shared/form/Input/input';
import ProgressDateComp from '../ProgressDateComp';
import {
  getProjectStatusList,
  getProjectTaskDetails,
  taskApproveRejectRequest,
  updateProjectStatus,
  updateProjectTaskRemark,
} from '../../../../../services/project-service';
import { AppConstants } from '../../../../../constants/app-constants';
import { capitalize } from '../../../../../utils/string';
import { getReaminingDaysPercentage } from '../../../../../utils/project';
import { formatDate } from '../../../../../libs/date/format';
import { useEnqueueSnackbar } from '../../../../../components/shared/toast-provider/toastHook';
import Loader from '../../../../../components/shared/loader';
import { sortBy } from 'lodash';
import ApproveRejectAction from '../../../../../components/shared/approve-reject';
import { useAppSelector } from '../../../../../redux/hooks';
import { handleError } from '../../../../../utils/errorHandling';
import BackdropOverlay from '../../../../../components/shared/backdropOverlay';

const TaskDetails = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const enqueueSnackbar = useEnqueueSnackbar();

  const [openDependencyDialog, setOpenDependencyDialog] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const [taskDetails, setTaskDetails] = useState({});
  const [projectStatuses, setProjectStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [showApproveTool, setApproveTool] = useState(false);

  const [pageAccess, setPageAccess] = useState(false);

  const projectTeams = ['Buying Team', 'Finance Team', 'Consulting Team', 'Reviewing Team'];

  const [details, setDetails] = useState([]);

  const userInfo = useAppSelector(state => state.auth);

  const loggedInUserTeams = useMemo(() => {
    return userInfo?.teams?.map(x => x.id);
  }, [userInfo]);

  useEffect(() => {
    fetchProjectStatusList();
  }, []);

  useEffect(() => {
    if (projectStatuses.length) {
      fetchTaskDetails();
    }
  }, [projectStatuses]);

  const showApprovalToolbar = (allowedTeams, requestStatus) => {
    let flag = false;
    if (requestStatus === 'IN_PROGRESS') {
      allowedTeams.forEach(team => {
        if ([...loggedInUserTeams].includes(team)) {
          flag = true;
        }
      });
    }
    return flag;
  };

  const fetchTaskDetails = async () => {
    setIsloading(true);
    await getProjectTaskDetails(params?.taskId)
      .then(res => {
        const { data } = res.data;
        formatProjectData(data);
      })
      .catch(error => {
        const { status = '' } = error?.response?.data || {};
        if (status === AppConstants.HTTP_FORBIDDEN) {
          setPageAccess(true);
        }
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const fetchProjectStatusList = async () => {
    setIsloading(true);
    await getProjectStatusList('TASK')
      .then(res => {
        const { data } = res.data;
        const t = data.map(x => x);
        setProjectStatuses(t);
      })
      .catch(error => {
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const updateProjectTaskRemarkCall = useCallback(
    async (params, payload, callBack = () => null) => {
      setIsloading(true);
      await updateProjectTaskRemark(params, payload)
        .then(res => {
          callBack();
          fetchTaskDetails();
        })
        .catch(error => {
          let message = handleError(error);
          enqueueSnackbar(message, 'error');
        })
        .finally(() => {
          setIsloading(false);
        });
    },
    [],
  );

  const updateTaskStatusCall = async status => {
    setIsloading(true);
    const payload = {
      'entityType': AppConstants.ENTITY_TYPE_TASK,
      'projectId': params?.id,
      'taskId': params?.taskId,
      'status': status,
    };

    await updateProjectStatus(payload)
      .then(res => {
        fetchTaskDetails();
      })
      .catch(error => {
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
        setSelectedStatus(taskDetails?.status);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const taskApprovalRequestCall = async status => {
    setIsloading(true);
    await taskApproveRejectRequest({
      'projectId': parseInt(params?.id),
      'taskId': parseInt(params?.taskId),
      'approvalId': taskDetails?.projectApproval?.id,
      'status': status,
    })
      .then(res => {
        enqueueSnackbar(`Request ${capitalize(status)} sucessfully.`, 'success');
        fetchTaskDetails();
      })
      .catch(error => {
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
        setIsloading(false);
      });
  };

  useEffect(() => {
    if (taskDetails?.status && taskDetails?.status !== selectedStatus) {
      updateTaskStatusCall(selectedStatus);
    }
  }, [selectedStatus]);

  const formatProjectData = response => {
    const {
      accountableTeam = '-',
      consultingTeam = '-',
      responsibleTeam = '-',
      informedTeam = [],
      task: { duration = '-', descrption = '-', name = '-', tutorialLink = '' },
      status = '',
      startDate = '',
      endDate = '',
      statusModifiedDate = '',
      subTask = [],
      projectApproval = null,
      assignedTeam = [],
      projectStatus = '',
    } = response;
    setDetails([
      {
        name: 'Responsible Team',
        description: responsibleTeam?.length ? responsibleTeam.join(', ') : '-',
      },
      {
        name: 'Consulting Team',
        description: consultingTeam?.length ? consultingTeam.join(', ') : '-',
      },
      { name: 'Timeline', description: `${duration} Days` || '-' },
      {
        name: 'Accountable Team',
        description: accountableTeam?.length ? accountableTeam.join(', ') : '-',
      },
      { name: 'Informed Team', description: informedTeam?.length ? informedTeam.join(', ') : '-' },
    ]);
    setSelectedStatus(status);

    const { parentData } = formateTaskList(subTask);
    const { progress, remainingDays } = getReaminingDaysPercentage(startDate, endDate);

    setApproveTool(
      status === 'COMPLETED'
        ? showApprovalToolbar(projectApproval?.approverTeamIds, projectApproval?.requestStatus)
        : false,
    );

    setTaskDetails({
      ...response,
      descrption,
      name,
      startDate: startDate ? formatDate(startDate, 'do MMMM, yyyy') : '-',
      endDate: endDate ? formatDate(endDate, 'do MMMM, yyyy') : '-',
      statusModifiedDate: statusModifiedDate
        ? formatDate(statusModifiedDate, `do MMMM, yyyy 'at' hh:mm a`)
        : '-',
      taskModels: sortBy(parentData, 'externalId'),
      progress,
      remainingDays,
      tutorialLink,
      assignTeamStr: assignedTeam?.join(', ') || '-',
      projectStatus: projectStatus,
    });
  };

  const formateTaskList = data => {
    let parentData = [];

    sortBy(data, 'id').forEach(ele => {
      const {
        id = '-',
        startDate,
        endDate,
        status = '-',
        task: { name = '-', externalId = '' },
        remarks = '',
        assignedTeam = '',
        requestStatus = '',
      } = ele || {};
      parentData.push({
        remarkId: id,
        id: id,
        name: name,
        startDate: startDate ? formatDate(startDate, 'do MMM, yyyy') : '-',
        endDate: endDate ? formatDate(endDate, 'do MMM, yyyy') : '-',
        status: status,
        assignedTeam: assignedTeam, //? getFirstCharOfEveryWord(assignedTeam) : '-',
        remark: remarks,
        externalId: externalId,
        requestStatus,
      });
    });

    return { parentData };
  };

  const handleViewTaskDetails = useCallback(
    data => {
      if (data?.id) {
        navigate(`/app/projects/${params.id}/view-project/${params?.taskId}/${data?.id}`, {
          replace: false,
        });
      }
    },
    [navigate, params.id],
  );

  const columnDefs = useMemo(
    () => columns(handleViewTaskDetails, updateProjectTaskRemarkCall, taskDetails?.projectStatus),
    [handleViewTaskDetails, updateProjectTaskRemarkCall, taskDetails],
  );

  const ProjectDetailsTable = useMemo(
    () => <Table variant="mui-table" data={taskDetails?.taskModels || []} columns={columnDefs} />,
    [columnDefs, params.taskId, taskDetails],
  );

  const freezeAll = useMemo(() => {
    return taskDetails?.projectStatus === AppConstants.PROJECT_STATUS_CANCEL;
  }, [taskDetails]);

  return (
    <>
      <BackdropOverlay
        open={pageAccess}
        pageWarning={AppConstants.PAGE_ACCESS_ERROR}
        buttonLabel="Redirect To Project"
        clickAction={() => {
          navigate(`/app/projects/${params.id}/view-project/`);
        }}
        autharizationWarning={true}
      />
      <Loader isLoading={isLoading} />
      <Card sx={{ alignItems: 'flex-start', minHeight: '100vh' }}>
        {taskDetails?.projectApproval && showApproveTool && !freezeAll && (
          <ApproveRejectAction
            onApprove={() => {
              taskApprovalRequestCall(AppConstants.EDIT_REQUEST_APPROVED);
            }}
            onReject={() => {
              taskApprovalRequestCall(AppConstants.EDIT_REQUEST_REJECTED);
            }}
          />
        )}
        <Header
          breadcrumbs={[{ name: decodeURIComponent(taskDetails?.task?.externalId || '-') }]}
          backHref={`/app/projects/${params.id}/view-project/`}
          rightActions={
            <Box>
              {taskDetails?.projectApproval?.requestStatus ===
                AppConstants.GCP_STATUS_IN_PROGRESS && (
                <Chip
                  status={''}
                  label={<Typography variant="Medium-14">Approval Pending</Typography>}
                  styles={{ marginRight: '15px' }}
                />
              )}
              <Chip
                status={
                  taskDetails?.status === 'CANCELLED'
                    ? 'error'
                    : taskDetails?.status === 'COMPLETED'
                      ? 'success'
                      : ''
                }
                label={
                  <Typography variant="Medium-14">{capitalize(taskDetails?.status)}</Typography>
                }
              />
            </Box>
          }
        />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '1.5rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: '1.5rem',
            }}
          >
            <Typography variant="SemiBold-18">{taskDetails?.name || '-'}</Typography>
            <Typography variant="Regular-14">{taskDetails?.descrption || '-'}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: '1.5rem',
              width: '100%',
            }}
          >
            <Typography variant="SemiBold-18" sx={{ flex: '1' }}>
              Tutorial Link
            </Typography>
            <Button
              label={<Typography variant="Medium-14">View</Typography>}
              onClick={() => window.open(taskDetails?.tutorialLink)}
              sx={{ marginTop: '-5px' }}
              disabled={!taskDetails?.tutorialLink}
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Accordion
              summary={
                <Typography variant="Medium-14" color={theme.palette.text['100']}>
                  Details
                </Typography>
              }
              details={
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '40px',
                    rowGap: '16px',
                  }}
                >
                  {details.map(detail => (
                    <Box
                      key={detail.name}
                      sx={{
                        width: 'calc((100%/3) - 27px)',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '24px',
                        backgroundColor: theme.palette.background,
                        borderRadius: '6px',
                        border: `1px solid ${theme.palette.text['500']}`,
                        padding: '12px',
                      }}
                    >
                      <Box sx={{ width: 'calc(50% - 12px)' }}>
                        <OverflowTooltip
                          text={detail.name}
                          sx={{ color: theme.palette.text['100'] }}
                        >
                          <Typography variant="Medium-14" color={theme.palette.text['300']}>
                            {detail.name}
                          </Typography>
                        </OverflowTooltip>
                      </Box>
                      <Box sx={{ width: 'calc(50% - 12px)' }}>
                        <OverflowTooltip
                          text={detail.description}
                          sx={{ color: theme.palette.text['100'] }}
                        >
                          {detail.description}
                        </OverflowTooltip>
                      </Box>
                    </Box>
                  ))}
                </Box>
              }
            />
          </Box>

          <ProgressDateComp
            remainingDays={taskDetails?.remainingDays}
            progress={taskDetails?.progress}
            startDate={taskDetails?.startDate}
            endDate={taskDetails?.endDate}
            statusModifiedDate={taskDetails?.statusModifiedDate}
            userName={taskDetails?.userName || ''}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '1.5rem',
          }}
        >
          <Box sx={{ width: '210px' }}>
            <Select
              id="project-status"
              options={projectStatuses}
              placeholder="Select Project Status"
              optionMapFunction={options =>
                options?.map(x => ({ id: x, label: capitalize(x), value: x }))
              }
              value={selectedStatus}
              menuHeight="400px"
              selectHeight="40px"
              onChange={event => {
                setSelectedStatus(event.target.value);
              }}
              disabled={freezeAll}
            />
          </Box>
          <Box sx={{ width: '450px' }}>
            <Select
              id="project-team"
              options={[...projectTeams, taskDetails?.assignTeamStr]}
              placeholder="Select Project Status"
              optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
              value={taskDetails?.assignTeamStr || '-'}
              startIcon={
                <Typography variant="Regular-14" color={theme.palette.text['100']}>
                  Assigned to:
                </Typography>
              }
              menuHeight="400px"
              selectHeight="40px"
              disabled={true}
            />
          </Box>
          {/* <Button
            startIcon={<DependencyIcon fill={theme.palette.primary.main} />}
            label={<Typography variant="Medium-14">{1} Dependency</Typography>}
            onClick={() => setOpenDependencyDialog(true)}
          /> */}
          <Dialog
            open={openDependencyDialog}
            onClose={() => setOpenDependencyDialog(false)}
            width="668px"
            headline={
              <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
                View Dependency
              </Typography>
            }
            description={
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  gap: '24px',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '16px',
                  }}
                >
                  <Typography variant="Regular-16">Predecessors</Typography>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '16px',
                    }}
                  >
                    <Box sx={{ width: 'calc(60% - 8px)' }}>
                      <Input label="Sub Task" value="Lorem Ipsum Task" disabled />
                    </Box>
                    <Box sx={{ width: 'calc(40% - 8px)' }}>
                      <Input value="Start to Finish" disabled />
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ width: '100%' }} />
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '16px',
                  }}
                >
                  <Typography variant="Regular-16">Successors</Typography>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '16px',
                    }}
                  >
                    <Box sx={{ width: 'calc(60% - 8px)' }}>
                      <Input label="Sub Task" value="Lorem Ipsum Task" disabled />
                    </Box>
                    <Box sx={{ width: 'calc(40% - 8px)' }}>
                      <Input value="Start to Finish" disabled />
                    </Box>
                  </Box>
                </Box>{' '}
              </Box>
            }
            showDialogActions={false}
          />
        </Box>
        {ProjectDetailsTable}
        {/* {taskDetails?.taskModels && taskDetails?.taskModels.length === 0 && !isLoading && (
          <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
            <Typography variant="Medium-16">There are no tasks found</Typography>
          </div>
        )} */}
      </Card>
    </>
  );
};

export default TaskDetails;
