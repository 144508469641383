import { ProductionQuantityLimits } from '@mui/icons-material';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export async function requestPermission() {
  const permission = await Notification.requestPermission();

  if (permission === 'granted') {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });

    return token;
  } else if (permission === 'denied') {
    alert('You denied the notification');
    throw new Error('Notification permission denied');
  }
}

// DEV
// firebaseConfig = {
//   apiKey: "AIzaSyAzlDYYO4oZEo3BgeNGx7ZT32IsWdgj4oY",
//   authDomain: "mindshare-d-marvel-fbase-cbb7.firebaseapp.com",
//   projectId: "mindshare-d-marvel-fbase-cbb7",
//   storageBucket: "mindshare-d-marvel-fbase-cbb7.firebasestorage.app",
//   messagingSenderId: "428547317541",
//   appId: "1:428547317541:web:a4a98f377a363e6856c4cc",
// };

// UAT
// const firebaseConfig = {
//   apiKey: "AIzaSyDisizXy-_c4SvYaya52uLseo2qz72up84",
//   authDomain: "mindshare-u-marvel-fbase-b686.firebaseapp.com",
//   projectId: "mindshare-u-marvel-fbase-b686",
//   storageBucket: "mindshare-u-marvel-fbase-b686.firebasestorage.app",
//   messagingSenderId: "562510661723",
//   appId: "1:562510661723:web:5f046637ba2543f3fe177e",
// };

// PROD
//  firebaseConfig = {
//   apiKey: "AIzaSyAybkM_bapX9V-AWYj7DzbIeGRHrnCF1r0",
//   authDomain: "mindshare-p-marvel-fbase-bbb0.firebaseapp.com",
//   projectId: "mindshare-p-marvel-fbase-bbb0",
//   storageBucket: "mindshare-p-marvel-fbase-bbb0.firebasestorage.app",
//   messagingSenderId: "259016880294",
//   appId: "1:259016880294:web:2c198951cd3503e43a0467",
// };
