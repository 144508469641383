import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CrossIcon, ErrorIcon, SearchIcon, SuccesIcon } from '../../../../libs/svg-icons/icons';
import Tooltip from '../../tooltip/index';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';

/**
 * Custom date-picker field over MUI DatePicker.
 *
 * @param {string} id - The `id` of `date-picker` element.
 * @param {string} inputHeight - The `height` of `date-picker` element.
 * @param {string} type - The `type` of `date-picker` element.
 * @param {string} label - The `label` of `date-picker` element.
 * @param {string} name - The `name` of `date-picker` element.
 * @param {string} value - The `value` of `date-picker` element.
 * @param {function} onChange - The function that is triggered when there is a change in the `date-picker` element.
 * @param {function} onBlur - The function that is triggered when the`date-picker` element is blurred.
 * @param {function} onKeyDown - The function that is triggered when any key id pressend inside the`date-picker` element.
 * @param {boolean} required - If `required` is true, the `date-picker` element will require a value and an asteric will be shown after the label.
 * @param {string} placeholder - The short hint displayed in the `date-picker` element before the user enters a value.
 * @param {boolean} disabled - If `disabled` is true, the `date-picker` element will be disabled.
 * @param {Element} startIcon - The icon that is shown at the beginning or left end of the `date-picker` element.
 * @param {Element} endIcon - The icon that is shown at the right end of the `date-picker` element.
 * @param {string} color - The `color` for the `date-picker` element.
 * @param {string} error - If `error` message is present, it will error success message at the bottom of the `date-picker` element and the border of the `date-picker` element will be of color error.
 * @param {string} success - If `success` message is present, it will show success message at the bottom of the `date-picker` element and the border of the `date-picker` element will be of color success.
 * @param {boolean} showErrorIcon - If `showErrorIcon` is true, it will show error icon at the right end of the `date-picker` element.
 * @param {boolean} showSuccessIcon - If `showSuccessIcon` is true, it will show success icon at the right end of the `date-picker` element.
 * @param {string} helperText - Any text that we want to show at the bottom of the `date-picker` element, as a description.
 * @param {string} iconInfo - If `startIcon` or `endIcon` is present,`iconInfo` contents will be shown in a tooltip if the `startIcon` or `endIcon` is hovered.
 * @param {boolean} searchable - If `true`, the `date-picker` element will have a search icon at the end.
 * @param {function} onSearchCloseClick - If `date-picker` element is searchable, then this function will be triggered if clicking the cross icon is clicked while searching.
 * @param {boolean} autoFocus - If `true`, the `date-picker` element is focused during the first mount.
 * @returns {Element} The `date-picker` element.
 */

import { createTheme, ThemeProvider } from '@mui/material/styles';

const DatePicker = (
  {
    id = '',
    inputHeight = '',
    type = 'text',
    label = '',
    name = '',
    value = '',
    onChange = () => null,
    onBlur = () => null,
    onKeyDown = () => null,
    required = false,
    placeholder = '',
    disabled = false,
    startIcon = null,
    endIcon = null,
    color = null,
    error = '',
    success = '',
    showErrorIcon = false,
    showSuccessIcon = false,
    helperText = '',
    iconInfo = null,
    searchable = false,
    onSearchCloseClick = null,
    autoFocus = false,
    maxDate = null,
    minDate = null,
    isYearPicker = false,
  },
  ref,
) => {
  const [inputValue, setInputValue] = useState();

  const themeCal = createTheme({
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            height: isYearPicker ? '300px' : '330px !important',
            maxHeight: isYearPicker ? '300px' : '330px important',
            backgroundColor: '#fff',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              height: `${inputHeight}`,
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    if (value) setInputValue(value);
    else setInputValue(null);
  }, [value]);
  const theme = useTheme();

  const handleChange = e => {
    setInputValue(e);
    onChange(e);
  };

  const handleSearchCloseClicked = () => {
    onSearchCloseClick && onSearchCloseClick();
    setInputValue('');
  };

  const handleKeyDown = e => {
    onKeyDown(e);
  };

  const startIconComponent = useMemo(
    () =>
      startIcon ? (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="start">{startIcon}</InputAdornment>
        </Tooltip>
      ) : null,
    [iconInfo, startIcon],
  );

  const endIconComponent = useMemo(() => {
    if (searchable)
      if (!!inputValue)
        return (
          <InputAdornment
            position="end"
            sx={{ cursor: 'pointer' }}
            onClick={handleSearchCloseClicked}
          >
            <CrossIcon fill="var(--text-400)" />
          </InputAdornment>
        );
      else
        return (
          <InputAdornment position="end">
            <SearchIcon fill="var(--text-400)" />
          </InputAdornment>
        );

    if (!!error && showErrorIcon)
      return (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="end">
            <ErrorIcon fill="var(--error)" />
          </InputAdornment>
        </Tooltip>
      );
    if (!!success && showSuccessIcon)
      return (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="end">
            <SuccesIcon fill="var(--success)" />
          </InputAdornment>
        </Tooltip>
      );
    if (endIcon)
      return (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="end">{endIcon}</InputAdornment>
        </Tooltip>
      );
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchable, inputValue, error, showErrorIcon, iconInfo, success, showSuccessIcon, endIcon]);

  const inputColor = useMemo(() => {
    if (color) return color;
    if (!!error) return 'error';
    if (!!success) return 'success';
    return 'primary';
  }, [color, error, success]);

  const helperTextContent = useMemo(() => {
    if (helperText) return helperText;
    if (!!error) return error;
    if (!!success) return success;
    return null;
  }, [helperText, error, success]);

  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '& .MuiStack-root': {
          gap: '4px',
        },
      }}
    >
      <FormControl error={!!error} color={inputColor} disabled={disabled}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoItem
            label={
              <Typography color={theme.palette.text[100]} component="span" variant="Medium-16">
                {label} {required ? '*' : null}
              </Typography>
            }
          >
            <ThemeProvider theme={themeCal}>
              <MuiDatePicker
                className={`date-custom ${isYearPicker ? 'year-picker year-canvas' : ''}`}
                ref={ref}
                id={id ?? undefined}
                sx={{
                  height: `${inputHeight} !important`,
                  marginTop: '0px !important',
                  gridColumn: 'span 4 !important',
                }}
                type={type}
                autoFocus={autoFocus}
                variant="outlined"
                name={name}
                onChange={handleChange}
                onBlur={onBlur}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                value={inputValue}
                required={required}
                disabled={disabled}
                startAdornment={startIconComponent}
                endAdornment={endIconComponent}
                aria-describedby="component-helper-text"
                maxDate={maxDate}
                minDate={minDate}
                orientation={'landscape'}
                open={open}
                onClose={() => setOpen(false)}
                slotProps={{
                  textField: {
                    onClick: () => (disabled ? null : setOpen(true)),
                    readOnly: true,
                  },
                }}
                views={isYearPicker ? ['year'] : undefined}
              />
            </ThemeProvider>
          </DemoItem>
        </LocalizationProvider>
        <FormHelperText
          id="component-helper-text"
          sx={{
            color: !!success && 'var(--success)',
            margin: '0 !important',
          }}
        >
          {helperTextContent}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default forwardRef(DatePicker);
