import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Button from '../../../../../../components/shared/buttons';
import InputField from '../../../../../../components/shared/form/Input';
import Select from '../../../../../../components/shared/form/Select/select';
import MultiSelect from '../../../../../../components/shared/form/MultiSelect/multiSelect';
import DatePicker from '../../../../../../components/shared/form/date-picker';
import dayjs from 'dayjs';
import { AppConstants } from '../../../../../../constants/app-constants';
import { formatDate } from '../../../../../../libs/date/format';
import OverflowTooltip from '../../../../../../components/shared/tooltip/OverflowTooltip';
import { isValid } from 'date-fns';

const MediaPlanSubmission = ({
  fieldData = [],
  createUpdateSubtaskRequest = () => {},
  showReadyOnly = false,
  validationStatus = null,
  isValidate = false,
  freezeAll = false,
  isTable = false,
  calculateTableSubtaskDetails = () => {},
  calculationStatus = '',
}) => {
  const theme = useTheme();

  const [isChecking, setIsChecking] = useState(false);
  const [exceptionDetails, setExceptionDetails] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (fieldData.length) checkValidationErrors(fieldData);
  }, [fieldData]);

  const checkValidationErrors = (data = []) => {
    if (
      validationStatus === AppConstants.GCP_STATUS_VALIDATED ||
      validationStatus === AppConstants.GCP_STATUS_NOT_VALIDATED
    ) {
      setIsChecking(true);
      const exception = [];
      let error = false;

      data?.forEach(item => {
        const { name, validationError = null } = item || {};
        if (validationError?.message) {
          if (validationError?.errorType === 'error') {
            error = true;
          }
          exception.push({
            name: name,
            message: validationError?.message || '',
            type: validationError?.errorType || '',
          });
        }
      });

      setIsError(error);
      setExceptionDetails(exception);
    }
  };

  const isSaveDisabled = useMemo(() => {
    let temp = true;
    if (fieldData?.length) {
      fieldData.forEach(x => {
        if (x.isEditable) {
          temp = false;
        }
      });
    }
    return !temp;
  }, [fieldData]);

  const schema = useMemo(() => {
    let t = {};
    if (fieldData)
      fieldData.forEach(x => {
        if (x.isNullable) {
          if (x.fieldType === AppConstants.FIELDTYPE_MULTI_SELECT) {
            t[x.name] = yup.array().min(1, 'At least 1 value required').nullable('');
          } else if (x.fieldType === AppConstants.FIELDTYPE_DATE) {
            t[x.name] = yup.object().nullable('');
          } else {
            if (x.acceptableValues === 'Number') {
              t[x.name] = yup
                .number()
                .typeError('Must be a number')
                .positive('Must be a positive number')
                .integer('Must be an integer')
                .nullable('');
            } else {
              t[x.name] = yup.string().nullable('');
            }
          }
        } else {
          if (x.fieldType === AppConstants.FIELDTYPE_MULTI_SELECT) {
            t[x.name] = yup.array().min(1, 'At least 1 value required').required('');
          } else if (x.fieldType === AppConstants.FIELDTYPE_DATE) {
            t[x.name] = yup.object().required('');
          } else {
            if (x.acceptableValues === 'Number') {
              t[x.name] = yup
                .number()
                .typeError('Must be a number')
                .positive('Must be a positive number')
                .integer('Must be an integer')
                .required('');
            } else {
              t[x.name] = yup.string().required('');
            }
          }
        }
      });
    return yup.object().shape({ ...t });
  }, [fieldData]);

  const getValueAsPerFieldType = (item, forPayload = false, value = '') => {
    const { fieldType, columnValue, name } = item;

    if (item.name === 'Date Created') {
      return formatDate(new Date(), 'dd/MM/yyyy');
    }

    if (fieldType === 'DATE') {
      return forPayload
        ? formatDate(new Date(value), 'dd/MM/yyyy')
        : columnValue
          ? dayjs(columnValue || '')
          : '';
    } else if (AppConstants.FIELDTYPE_MULTI_SELECT === fieldType) {
      let t = columnValue?.split(',');
      return forPayload ? value?.join(',') : t;
    } else if (AppConstants.FIELDTYPE_SINGLE_SELECT === fieldType) {
      let t = columnValue?.split(',');
      return forPayload ? value : t;
    } else {
      return forPayload ? value : columnValue;
    }
  };

  const methods = useForm(
    {
      mode: 'onTouched',
      resolver: yupResolver(schema),
      defaultValues: fieldData?.reduce(
        (a, x) => ({
          ...a,
          [x.name]: getValueAsPerFieldType(x),
        }),
        {},
      ),
    },
    [schema],
  );

  const getPayload = data => {
    const t = [];
    fieldData?.forEach(x => {
      const { id, isEdited, isEditable, name } = x;
      const temp = {
        id,
        isEdited,
        columnValue: `${getValueAsPerFieldType(x, true, data[name])}`,
      };
      if (isEditable || name === 'Date Created') {
        t.push(temp);
      }
    });
    return t;
  };

  const onSubmit = state => {
    if (!isTable) {
      const data = state || methods.getValues();
      setIsChecking(false);
      const payload = getPayload(data);

      if (isValidate && !isError) {
        const continueWithException = exceptionDetails?.length > 0 ? true : false;
        createUpdateSubtaskRequest(payload, continueWithException);
      } else {
        createUpdateSubtaskRequest(payload, false);
      }
    }
  };

  const getValidationDetails = useCallback(
    name => {
      if (isChecking)
        return {
          success: !exceptionDetails?.find(x => x.name === name),
          showSuccessIcon: !exceptionDetails?.find(x => x.name === name),
          error: !!exceptionDetails?.find(x => x.name === name && x.type === 'error'),
          showErrorIcon: !!exceptionDetails?.find(x => x.name === name && x.type === 'error'),
          warning: !!exceptionDetails?.find(x => x.name === name && x.type === 'warning'),
          showWarningIcon: !!exceptionDetails?.find(x => x.name === name && x.type === 'warning'),
          iconInfo: exceptionDetails?.find(x => x.name === name)?.message,
        };
      return {};
    },
    [isChecking, exceptionDetails],
  );

  const FormDetails = useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '24px',
        }}
      >
        {fieldData?.map(x => (
          <Box sx={{ width: 'calc(33.33% - 16.33px)' }}>
            {x?.fieldType === AppConstants.FIELDTYPE_TEXT && (
              <InputField
                id={x?.name}
                type="text"
                label={x?.name}
                disabled={!x.isEditable}
                {...methods.register(x?.name)}
                {...getValidationDetails(x?.name)}
              />
            )}

            {x?.fieldType === AppConstants.FIELDTYPE_DATE && (
              <DatePicker
                id={x.name}
                label={x?.name}
                subtask={true}
                disabled={!x.isEditable}
                {...methods.register(x?.name)}
                onChange={() => null}
                {...getValidationDetails(x?.name)}
              />
            )}
            {x.fieldType === AppConstants.FIELDTYPE_SINGLE_SELECT && (
              <Select
                id={x.name}
                label={x.name}
                inputHeight="48px"
                options={x?.acceptableValues?.split(',') || []}
                optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                menuHeight="400px"
                menuWidth="12rem"
                selectHeight="48px"
                disabled={!x.isEditable}
                value={[methods.getValues(x.name)]}
                {...methods.register(x.name)}
                {...getValidationDetails(x.name)}
              />
            )}
            {x.fieldType === AppConstants.FIELDTYPE_MULTI_SELECT && (
              <MultiSelect
                id={x.name}
                label={x.name}
                inputHeight="48px"
                options={x?.acceptableValues?.split(',') || []}
                optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                menuHeight="400px"
                menuWidth="12rem"
                selectHeight="48px"
                disabled={!x.isEditable}
                value={methods.getValues(x.name) || []}
                {...methods.register(x.name)}
                {...getValidationDetails(x.name)}
              />
            )}
          </Box>
        ))}
      </Box>
    ),
    [methods, getValidationDetails, fieldData],
  );

  const DetailsCard = useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '24px',
        }}
      >
        {fieldData?.map(x => {
          let value = x.columnValue
            ? getValueAsPerFieldType(
                x,
                true,
                AppConstants.FIELDTYPE_MULTI_SELECT === x?.fieldType
                  ? x.columnValue.split(',')
                  : x.columnValue,
              ) // 7th Oct 2024-: Added this fix due to BE changes; This is Quick Fix (internal tirnary operator)
            : '-';

          const isUrlInValue = value?.includes('http');
          return (
            <Box
              sx={{
                width: 'calc(33.33% - 16.33px)',
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <Box>
                <Typography variant="Medium-14" color={theme.palette.text['100']}>
                  <OverflowTooltip text={x.name}>{x.name}</OverflowTooltip>
                </Typography>
              </Box>
              <Box>
                <Typography variant="Regular-14" color={theme.palette.text['200']}>
                  <Box
                    sx={
                      isUrlInValue
                        ? {
                            cursor: 'pointer',
                            '& :hover': { color: 'blue' },
                          }
                        : {}
                    }
                    onClick={() => {
                      if (isUrlInValue) {
                        window.open(value, '_blank');
                      }
                    }}
                  >
                    <OverflowTooltip text={value}>{value}</OverflowTooltip>
                  </Box>
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    ),
    [theme.palette.text],
  );

  const isEditMode = useMemo(() => {
    return !showReadyOnly || validationStatus === AppConstants.GCP_STATUS_NOT_VALIDATED;
  }, [showReadyOnly, validationStatus]);

  const btnLabel = useMemo(() => {
    let str = 'Save'; //Submit

    if (isTable) {
      str = 'Check & Calculate';
    } else if (isValidate && exceptionDetails?.length > 0 && !isError) {
      str = 'Continue with Exception';
    } else if (isValidate || isError) {
      str = 'Check & Validate';
    }
    // else if (!isValidate) {
    //   str = 'Calculate & Submit';
    // }

    return str;
  }, [exceptionDetails, isValidate, isTable]);

  const isBtnDisable = useMemo(() => {
    if (isTable) {
      return calculationStatus === AppConstants.GCP_STATUS_IN_PROGRESS || freezeAll;
    } else {
      let flag = false;

      fieldData?.forEach(x => {
        if (x.isEditable && !flag && !x.isNullable) {
          if (!methods.getValues(x.name)) {
            flag = true;
          }
        }
      });

      return flag || freezeAll;
    }
  }, [methods.formState, freezeAll, isTable, calculationStatus, methods.watch()]);

  return (
    <Box
      sx={{
        width: '100%',
        padding: '24px 0',
        borderTop: `1px solid ${theme.palette.text['500']}`,
        borderBottom: `1px solid ${theme.palette.text['500']}`,
      }}
    >
      <Box
        sx={{
          borderRadius: '8px',
          padding: !isEditMode ? '24px 32px' : '0',
          background: !isEditMode ? theme.palette.background : 'transparent',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '940px' }}>
          {isEditMode ? (
            <FormProvider {...methods}>
              <form
                //  onSubmit={methods.handleSubmit(onSubmit)}
                style={{ width: '100%' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    gap: '30px',
                  }}
                >
                  {FormDetails}
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      label={btnLabel}
                      disabled={isBtnDisable}
                      sx={{ height: '50px' }}
                      onClick={() => {
                        if (isTable) {
                          calculateTableSubtaskDetails();
                        } else {
                          onSubmit();
                        }
                      }}
                    />
                  </Box>
                </Box>
              </form>
            </FormProvider>
          ) : (
            DetailsCard
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MediaPlanSubmission;
