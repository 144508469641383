import { Avatar, Box, Divider, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../../components/shared/buttons';
import Card from '../../../../components/shared/card';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { DownloadIcon, PlansIcon, ProjectsIcon } from '../../../../libs/svg-icons/icons';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import {
  getNotificationCount,
  getNotificationList,
  markNotificationsAsRead,
} from '../../../../services/notification-service';
import { AppConstants } from '../../../../constants/app-constants';
import { useEnqueueSnackbar } from '../../../../components/shared/toast-provider/toastHook';
import EmptyTableIllustration from '../../../../assets/images/empty-table-illustration.svg';
import Loader from '../../../../components/shared/loader';
import OverflowTooltip from '../../../../components/shared/tooltip/OverflowTooltip';
import { notificationActions } from '../../../../redux/slice';
import { useAppDispatch } from '../../../../redux/hooks';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import { capitalize } from '../../../../utils/string';

const SeverityChip = ({ text = '' }) => {
  const chipColors = {
    'INFO': '#9ECFFF',
    'SUCCESS': '#B2E9E7',
    'ERROR': '#EFB6A3',
    'WARNING': '#FFEDCA',
    'NEED_ACTION': '#FD7E14',
    '': '',
  };
  return (
    <div
      style={{
        borderRadius: '3px',
        background: chipColors[text],
        margin: 'auto 10px',
        padding: '0px 10px',
        minWidth: '100px',
        textAlign: 'center',
      }}
    >
      <Typography variant="Medium-12" style={{ lineHeight: '12px' }}>
        {capitalize(text)}
      </Typography>
    </div>
  );
};

const notificationIcons = isActive => ({
  MEDIA_PLAN: <PlansIcon fill={isActive ? 'var(--primary)' : 'var(--background)'} />,
  INTERNAL_BRIEF: <PlansIcon fill={isActive ? 'var(--primary)' : 'var(--background)'} />,
  PROJECT: <ProjectsIcon fill={isActive ? 'var(--primary)' : 'var(--background)'} />,
  MEDIA_PLAN_DETAILS: <PlansIcon fill={isActive ? 'var(--primary)' : 'var(--background)'} />,
  'default': '!',
});

const NotificationCard = ({ index = 0, data = null, markAsRead = () => {} }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    activityType = '',
    days = 0,
    entityType = '',
    date = new Date('Wed Apr 03 2024 00:16:20 GMT+0530 (India Standard Time)'),
    isRead = false,
    metadata = '',
    message = '',
    title = '',
    uuid = '',
    senderName = '',
    createdAt = '',
    severityType = '',
  } = data || {};

  const getDaysStr = useMemo(() => {
    if (days === 0) {
      return 'Today';
    } else if (days === 1) {
      return 'Yesterday';
    } else {
      return `${days} days ago`;
    }
  }, [days]);

  const notificationRedirect = url => {
    const [base = '', path = ''] = url?.split('.com');

    navigate(path, {
      state: { activityType },
    });
  };

  const getInitials = name => {
    return name
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase()) // Take the first char of each word and convert to uppercase
      .join(''); // Join the initials
  };

  const formatTimestampToRelative = timestamp => {
    const now = dayjs(new Date());
    const givenTime = dayjs(new Date(timestamp));

    const diffInMin = now.diff(givenTime, 'minutes');
    const diffInHours = now.diff(givenTime, 'hour');
    const diffInDays = now.diff(givenTime, 'day');

    if (diffInHours === 0) {
      return `${diffInMin || 1} minutes ago`;
    } else if (diffInHours < 24) {
      return diffInHours === 1 ? '1h' : `${diffInHours}h`;
    } else if (diffInDays === 1) {
      return 'Yesterday';
    } else {
      return `${diffInDays} days ago`;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        background: !isRead ? '#F7F7F7' : '',
        height: '90px',
        display: 'flex',
        cursor: 'pointer',
        padding: '16px',
      }}
      onClick={() => {
        if (!isRead) markAsRead(uuid);
        notificationRedirect(metadata);
      }}
    >
      <div style={{ display: 'contents' }}>
        <Avatar
          sx={{
            background: !isRead ? '#0A2756' : '#f4f4f4',
            color: '#fff',
            margin: 'auto 0',
            color: !isRead ? '#F4F4F4' : '#0A2756',
          }}
        >
          {activityType === 'COMMENT' ? (
            <Typography variant="Bold-14"> {getInitials(senderName)} </Typography>
          ) : (
            notificationIcons(isRead)[entityType || 'default']
          )}
        </Avatar>
      </div>
      <div style={{ paddingLeft: '19px', width: '100%', overflow: 'hidden' }}>
        <div style={{ marginBottom: '10px', display: 'flex', width: '100%' }}>
          <Typography variant="Medium-16">{title}</Typography>
          <span
            style={{
              borderRadius: '10px',
              height: '5px',
              width: '5px',
              background: '#0080FF',
              margin: 'auto 10px',
              display: 'inline-block',
            }}
          >
            {' '}
          </span>
          <Typography variant="Regular-14" sx={{ margin: 'auto 0px' }}>
            {formatTimestampToRelative(createdAt || new Date())}
          </Typography>
          <div style={{ width: '20px', marginLeft: 'auto', paddingRight: '50px' }}>
            {!isRead && (
              <div
                style={{
                  borderRadius: '10px',
                  height: '5px',
                  width: '5px',
                  background: '#0080FF',
                  margin: 'auto 10px',
                  display: 'inline-block',
                }}
              >
                {' '}
              </div>
            )}
          </div>
          {severityType && <SeverityChip text={severityType} />}
        </div>
        <OverflowTooltip text={message} sx={{ textAlign: 'left', paddingRight: '30px' }}>
          <Typography variant="Medium-14"> {message}</Typography>
        </OverflowTooltip>
      </div>
    </Box>
  );
};

const NotificationsList = () => {
  const theme = useTheme();
  const enqueueSnackbar = useEnqueueSnackbar();
  const dispatch = useAppDispatch();

  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const pageSize = 10;

  const fetchNotificationCount = async () => {
    await getNotificationCount()
      .then(response => {
        const { data = {} } = response.data || {};
        const { unreadCount = '0' } = data;
        dispatch(notificationActions.setNotificationCount({ notificationCount: unreadCount }));
      })
      .catch(error => {
        console.error(error);
        dispatch(notificationActions.setNotificationCount({ notificationCount: '!' }));
      });
  };

  const fetchNotificationList = async (page = 1) => {
    setIsLoading(true);
    await getNotificationList({ pageNo: page, pageSize })
      .then(response => {
        const { data = {} } = response.data;
        const {
          models = [],
          isFirst = false,
          isLast = false,
          totalPages = 0,
          totalElements = 0,
          unreadCount = 0,
        } = data;

        setPageNo(page);
        setIsLast(isLast);

        if (!isFirst) {
          setNotificationList([...notificationList, ...models]);
        } else {
          setNotificationList([...notificationList, ...models]);
        }
        dispatch(notificationActions.setNotificationCount({ notificationCount: unreadCount }));
        // fetchNotificationCount();
      })
      .catch(error => {
        const { data = {} } = error?.response || {};
        const { message = AppConstants.SOMETHING_WENT_WRONG } = data;
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchNotificationList();
  }, []);

  const markAllAsReadHandler = useCallback(() => {
    MarkAsReadNotificationCall();
  }, []);

  const MarkAsReadNotificationCall = async (id = null) => {
    setIsLoading(true);
    await markNotificationsAsRead(id ? [id] : [])
      .then(response => {
        const data = response.data;
        fetchNotificationList();
      })
      .catch(error => {
        const { data = {} } = error?.response || {};
        const { message = AppConstants.SOMETHING_WENT_WRONG } = data;
        enqueueSnackbar(message, 'error');
        setIsLoading(false);
      });
  };

  const emptyNotificationList = useMemo(() => {
    return (
      <Box
        sx={{
          width: '100%',
          marginTop: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <Box
          sx={{
            width: '30%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '30px',
          }}
        >
          <img src={EmptyTableIllustration} alt="No data present" width={300} height={300} />
          <Box
            sx={{
              width: '100%',
              minWidth: '386px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <Typography variant="Medium-18" color={theme.palette.text['100']}>
              {'There are no Notifications'}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }, []);

  const listNotificationComponent = useMemo(() => {
    return notificationList.map((ele, index) => {
      return (
        <>
          <NotificationCard index={index} data={ele} markAsRead={MarkAsReadNotificationCall} />
          {notificationList.length - 1 !== index && (
            <Divider sx={{ borderBottom: '1px solid #CDD1D7', margin: '8px 0px' }} />
          )}
        </>
      );
    });
  }, [notificationList]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Card
        sx={{
          minHeight: 'calc(100vh - 130px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography variant="Bold-28">NOTIFICATIONS</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1.25rem',
            }}
          >
            <Button
              key="button2"
              width="160px"
              onClick={markAllAsReadHandler}
              label="Mark all as Read"
              disabled={notificationList.length <= 0}
            />
          </Box>
        </Box>

        <Box sx={{ width: '100%', paddingBottom: '50px' }}>
          <InfiniteScroll
            dataLength={notificationList.length}
            next={() => {
              fetchNotificationList(pageNo + 1);
            }}
            hasMore={!isLast}
            loader={() => {
              return isLoading ? 'loading' : null;
            }}
            style={{ overflow: 'unset' }}
            scrollableTarget="table-container"
          >
            {notificationList.length
              ? listNotificationComponent
              : !isLoading && emptyNotificationList}
          </InfiniteScroll>
        </Box>
      </Card>
    </>
  );
};

export default NotificationsList;
